import React from 'react';
import { Input, Form, Checkbox } from 'antd';
import { Dialog, ViewTable, Layout, Button, Filter } from '@/components';
import { FormInstance } from 'antd/lib/form';
import { LibAuthCode } from 'lib/Auth';
import WebApi from '@/web/api';

export default class NoticePage extends React.PureComponent {
  state = {
    createModal: false,
    updateModal: false,
  }

  private table = React.createRef<ViewTable>();

  private createForm: FormInstance | null = null
  private updateForm: FormInstance | null = null

  private queryParams = {
    keyword: ''
  }

  private updateForms: any = {};

  render() {
    const { updateModal, createModal } = this.state;

    return <Layout.Page>
      <Layout.Toolbar title="公告列表">
        <Button authCode={LibAuthCode.notice.create} type="primary" onClick={() => this.setState({ createModal: true })}>新增公告</Button>
      </Layout.Toolbar>

      <Filter
        fields={[
          {
            type: 'input',
            field: 'keyword',
            name: '搜索',
            placeholder: '标题/内容'
          }
        ]}
        onChange={(values: any) => {
          this.queryParams.keyword = values.keyword;
          this.table.current?.reset(this.queryParams);
        }}
      />

      <ViewTable
        flex1
        fetch={data => {
          return WebApi.notice_paging({...data, ...this.queryParams})
        }}
        ref={this.table}
        rowKey={row => row.noticeId}
        scroll={{y: '100%', x: true}}
        columns={[{
          title: '标题',
          width: 300,
          dataIndex: 'title',
        }, {
          title: '内容',
          width: 'auto',
          dataIndex: 'content',
        }, {
          title: '可关闭',
          width: 70,
          align: 'center',
          dataIndex: 'closable',
          render: (value, row) => {
            return <Checkbox checked={value}
              onChange={e => {
                this.clickUpdateStatus({
                  noticeId: row.noticeId,
                  closable: e.target.checked,
                  enable: row.enable,
                  title: row.title,
                  content: row.content,
                });
              }}/>
          }
        }, {
          title: '启用',
          width: 70,
          align: 'center',
          dataIndex: 'enable',
          render: (value, row) => {
            return <Checkbox checked={value}
              onChange={e => {
                this.clickUpdateStatus({
                  noticeId: row.noticeId,
                  enable: e.target.checked,
                  closable: row.closable,
                  title: row.title,
                  content: row.content,
                });
              }}/>
          }
        }, {
          title: '发布时间',
          width: 180,
          dataIndex: 'createdAt',
        }]}

        actionButtons={[
          {
            type: 'update',
            authCode: LibAuthCode.notice.update,
            onClick: (row) => {
              this.updateForms = row;
              this.setState({
                updateModal: true,
              });
            },
          },
          {
            type: 'delete',
            authCode: LibAuthCode.notice.delete,
            onClick: row => {
              this.clickDelete(row);
            }
          }
        ]}
      />

      <Dialog title="新增" visible={createModal}
        full={false}
        onCancel={() => this.setState({ createModal: false })}
        onOk={this.clickCreate}>
        <div>
          <Form ref={r => this.createForm = r} labelCol={{span: 4}} initialValues={{closable: true, enable: true}}>
            <Form.Item name="title" label="标题" rules={[{
              required: true,
              message: '请填写标题',
            }]}>
              <Input placeholder="填写标题,限50字" maxLength={50}/>
            </Form.Item>
            <Form.Item name="content" label="内容" rules={[{
              required: true,
              message: '请填写内容'
            }]}>
              <Input.TextArea placeholder="填写内容" rows={6}/>
            </Form.Item>
            <Form.Item name="closable" valuePropName="checked" wrapperCol={{ offset: 4, span: 16 }} rules={[{
              required: true,
              message: '请填写内容'
            }]}>
              <Checkbox>用户可关闭</Checkbox>
            </Form.Item>
            <Form.Item name="enable" valuePropName="checked" wrapperCol={{ offset: 4, span: 16 }} rules={[{
              required: true,
              message: '请填写内容'
            }]}>
              <Checkbox>立即发布</Checkbox>
            </Form.Item>
          </Form>
        </div>
      </Dialog>

      <Dialog title="修改" visible={updateModal}
        onCancel={() => this.setState({ updateModal: false })}
        full={false}
        onOk={this.clickUpdate}>
        <div>
          <Form ref={r => this.updateForm = r} initialValues={this.updateForms}>
            <Form.Item name="title" label="标题" rules={[{
              required: true,
              message: '请填写标题',
            }]}>
              <Input placeholder="填写标题,限50字" maxLength={50}/>
            </Form.Item>
            <Form.Item name="content" label="内容" rules={[{
              required: true,
              message: '请填写内容'
            }]}>
              <Input.TextArea placeholder="填写内容" rows={6}/>
            </Form.Item>
          </Form>
        </div>
      </Dialog>
    </Layout.Page>
  }

  clickCreate = () => {
    this.createForm?.validateFields().then(values => {
      return WebApi.notice_create(values as any)
    })
    .then(() => {
      showSuccess.save();
      this.setState({
        createModal: false,
      });
      this.table.current?.reset(this.queryParams);
    })
  }

  clickUpdateStatus = (data) => {
    WebApi.notice_update(data).then(() => {
      showSuccess.save();
      this.setState({
        updateModal: false,
      });
      this.table.current?.reset(this.queryParams);
    })
  }

  clickUpdate = () => {
    this.updateForm?.validateFields()
    .then((values) => {
      return WebApi.notice_update({
        noticeId: this.updateForms.noticeId,
        title: values.title,
        content: values.content,
        enable: this.updateForms.enable,
        closable: this.updateForms.closable,
      })
    })
    .then(() => {
      showSuccess.save();
      this.setState({
        updateModal: false,
      });
      this.table.current?.refresh();
    })
  }

  clickDelete = (data) => {
    Dialog.confirm({
      content: `确认删除 “${data.title}” 吗？`,
      onOk: async () => {
        WebApi.notice_delete({
          noticeId: data.noticeId
        })
        .then(() => {
          showSuccess.delete();
          this.table.current?.refresh();
        })
      }
    });
  }
}